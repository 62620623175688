body,
html {
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  color: #444;
  background-color: #f1f1f1;
}

body {
  margin: 0;
  padding: 0;
  font-family: HelveticaNeue;
}

nav {
  padding: 10px;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  border-bottom: solid 2px #7eb5ee;
  /*testing sticky nav*/
  position: fixed;
  width: 100%;
  z-index: 100;
}

floater {
  top: 0px;
  right: 0px;
  position: absolute;
  padding: 10px;
  display: flex;
}

floater.profile-picture {
  width: 150px; /* Adjust the width and height to your desired size */
  height: 150px;
  border-radius: 50%; /* Creates a circular shape */
  object-fit: cover; /* Ensures the image fills the container without distortion */
}

.todaysMeetingsCheckbox {
  padding-bottom: 20px;
  padding-left: 35px;
}


.qr-code-container {
  background-color: #fff; /* Background color of the QR code */
  padding: 10px; /* Padding around the QR code */
  border-radius: 8px; /* Border radius */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Box shadow */
}

.x-small-font {
  font-size: x-small;
}

.x-small-font-italic {
  font-size: x-small;
  font-style: italic;
}

.small-font {
  font-size: small;
}

.small-font-italic {
  font-size: small;
  font-style: italic;
}

.small-font-red {
  font-size: small;
  color: #dc3545;
}

.loading-screen {
  background: gray;
  border: medium;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2000;
  display: flex;
  opacity: 0.5;
  top: 0px;
  left: 0px;
}

.circle {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
}

.profile-text {
  text-align: center;
  padding-top: 10px;
}

img.list-order-logo {
  padding: 3px;
}

img.orderlogo {
  padding: 10px;
}

img.orderlogo-profile {
  padding: 4px;
}

main {
  margin-top: 80px;
  flex: 1;
  height: 100%;
}

.leaderboard table,
.leaderboard th,
.leaderboard td {
  /*border: 1px solid black;*/
  border-bottom: 1px solid #e6e5e5;
  border-collapse: collapse;
  font-size: x-small;
  /*font-style: italic;*/
  min-width: 50px;
  padding-left: 5px;
  padding-right: 5px;
}
.leaderboard table {
  white-space: nowrap;
}

.leaderboard th {
  /*border: 1px solid black;
  border-radius: 10px 10px 0 0;*/
}

.leaderboard tbody td {
  text-align: right;
}

.leaderboard .username {
  /*min-width: 200px;*/
  text-align: left; /* Or any alignment you prefer */
}

.leaderboard .rank {
  min-width: 50px;
  text-align: center; /* Or any alignment you prefer */
}

.leaderboard tbody tr:hover {
  background-color: lightblue; /* Adjust color as needed */
}

.leaderboard-scroll {
  overflow: auto;
  justify-content: center;
  display: flex;
}
.leaderboard {
   overflow-x: auto;
   /*width: 100%;*/
}

.level-point-progress {
    font-size: small;
}

.level-point-progress-level {
    font-size: small;
    font-style: italic;
    text-align: right !important;
}

.level-point-progress-points {
    font-size: small;
    font-style: italic;
    text-align: left !important;
}

.level-point-progress-points-high {
    font-size: x-small;
}

.pre {
  border: 1px solid #999;
  page-break-inside: avoid;
  display: block;
  padding: 3px 3px 2px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 20px;
  word-break: break-word;
  word-wrap: normal;
  white-space: pre;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  /*font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 12px;*/
  color: #333333;
}

.pre-code {
    padding: 0;
    color: inherit;
    white-space: pre;
    white-space: pre-wrap;
    background-color: transparent;
    border: 0;
}

.page-container-feed-modification {
  margin-left: 50px;
  margin-right: 50px;
}

.feed-wall-left-panel {
  /*display: none;*/
  padding-top: 1em;
  /*min-width: 200px;*/
  margin-left: -20px;
  margin-right: -20px;
}

.feed-wall-right-panel {
  /*display: none;*/
  /*position: fixed;*/
  padding-top: 1em;
  /*min-width: 200px;*/
  margin-left: -20px;
  margin-right: -20px;
}

.feed-wall-right-panel-standard {
  width: fit-content;
}

.feed-wall-right-panel-fixed {
  /*display: none;*/
  position: fixed;
  width: min-content;
}

.user-post-box {
  /*display: block ruby;
  font-size: small;
  margin-right: 25px;*/

  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}



.user-post-box-text-field {
  background-color: #e6e6e6;
  padding: 10px;
  border-radius: 15px;
  /*margin: 5px;*/
  cursor: pointer;
  width: 100%;
}

.width-height-hundred {
  width: 100%;
  height:100%;
}

.pdf-preview {
  position: relative;
  top: -42px;
  left: 0;width:100%;
}

.video-container {
  max-width: 100%; /* Ensure the video container does not exceed its parent's width */
  overflow: hidden; /* Hide any overflow if the video dimensions exceed the container */
}

.video-container video {
  max-width: 100%; /* Ensure the video itself does not exceed the container's width */
  height: auto; /* Maintain aspect ratio by adjusting height automatically */
}

.sponsor-ad {
  display: none;
}

@media (max-width: 990px) {
  .feed-wall-left-panel {
    display: none;
  }
  .feed-wall-right-panel {
    display: none;
  }
  .sponsor-ad {
    display: block;
  }
  .page-container-feed-modification {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 600px) {
  .feed-button-text {
    display: none;
  }
  .user-post-box-image {}
}
@media (min-width: 575px) {
  .promote-button-col {
    bottom: 10px;
    position: absolute;
  }
  .top-up-option {
    height: 240px;
  }
}

@media (max-width: 420px) {
  .user-post-box-image {
    position: relative;
    top: -15px;
  }
}

.promote-button-col {
  display: flex;
  justify-content: center;
  /*bottom: 10px;
  position: absolute;*/
}
.promote-button {
  width: inherit;
}
.pricing-top-label {
  position: absolute;
  z-index: 10;
  background: #7eb5ee9e;
  right: 35px;
  border-radius: 12px;
  padding: 3px;
  font-size: small;
}
/*.top-up-option {
  height: inherit;
}*/

.advert1 {
  font-size: .8rem;
  padding: 10px;
  text-align: center;
  background-color: #f1f1f1;
  /*float: left;
  width: 20%; *//* Adjust the width as needed */
}
.advert2 {
  font-size: .8rem;
  padding: 10px;
  text-align: center;
  background-color: #f1f1f1;
  /*float: right;
  width: 20%; *//* Adjust the width as needed */
}

.cancelled_social {
  font-weight: bold;
  color: red;
  display: flex;
  justify-content: center;
  top: 10px;
  position: relative;
  font-size: larger;
}

.profile_account_credits {
  font-size: small;
  position: absolute;
  right: 40px;
  /*top: 20px;*/
  display: flex;
}

.disclaimer {
  font-size: small;
}

/* SVG ICONS IN CSS */
.svg_comment {
  display: inline-flex;
  top: 3px;
  position: relative;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Chat-Bubble-Text-Square--Streamline-Core"><desc>Chat Bubble Text Square Streamline Icon: https://streamlinehq.com</desc><g id="chat-bubble-text-square--messages-message-bubble-text-square-chat"><path id="Union" fill="%238fbffa" fill-rule="evenodd" d="M2.5 0A1.5 1.5 0 0 0 1 1.5v8.919l-0.974 2.923a0.5 0.5 0 0 0 0.595 0.643L4.561 13H12.5a1.5 1.5 0 0 0 1.5 -1.5v-10A1.5 1.5 0 0 0 12.5 0h-10Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="%232859c5" fill-rule="evenodd" d="M4.5 4.22a0.625 0.625 0 1 0 0 1.25h6a0.625 0.625 0 1 0 0 -1.25h-6Zm0 3.31a0.625 0.625 0 1 0 0 1.25h4a0.625 0.625 0 1 0 0 -1.25h-4Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>');
}

.svg_feed {
  display: inline-flex;
  top: 3px;
  position: relative;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Rss-Symbol--Streamline-Core" height="14" width="14"><desc>Rss Symbol Streamline Icon: https://streamlinehq.com</desc><g id="rss-symbol--wireless-feed-rss-transmit-broadcast"><path id="Union" fill="%232859c5" fill-rule="evenodd" d="M2.102 9.935a1.972 1.972 0 1 0 0 3.945 1.972 1.972 0 0 0 0 -3.945Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="%238fbffa" fill-rule="evenodd" d="M1.857 0.052a1 1 0 1 0 0 2 10.091 10.091 0 0 1 10.091 10.091 1 1 0 0 0 2 0A12.092 12.092 0 0 0 1.858 0.052Zm0 4.71a1 1 0 1 0 0 2 5.38 5.38 0 0 1 5.38 5.381 1 1 0 0 0 2 0 7.38 7.38 0 0 0 -7.38 -7.38Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>');
}

.svg_search {
  display: inline-flex;
  top: 3px;
  position: relative;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Definition-Search-Book--Streamline-Core"><desc>Definition Search Book Streamline Icon: https://streamlinehq.com</desc><g id="definition-search-book"><path id="Subtract" fill="%238fbffa" fill-rule="evenodd" d="M0.5 0.125a0.5 0.5 0 0 0 -0.5 0.5v10a0.5 0.5 0 0 0 0.5 0.5h4.756A4.492 4.492 0 0 1 5 9.625c0 -1.271 0.527 -2.42 1.375 -3.238v-4.96A2 2 0 0 0 4.5 0.126h-4Zm7.125 1.303v4.105a4.5 4.5 0 0 1 6.347 3.584H14V0.625a0.5 0.5 0 0 0 -0.5 -0.5h-4a2 2 0 0 0 -1.875 1.303Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union" fill="%232859c5" fill-rule="evenodd" d="M7.75 9.625a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1 -3.5 0Zm1.75 -3.25a3.25 3.25 0 1 0 1.706 6.017l1.263 1.263a0.75 0.75 0 0 0 1.06 -1.06l-1.262 -1.264A3.25 3.25 0 0 0 9.5 6.375Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>');
}

.svg_contribute {
  display: inline-flex;
  top: 3px;
  position: relative;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Application-Add--Streamline-Core"><desc>Application Add Streamline Icon: https://streamlinehq.com</desc><g id="application-add--application-new-add-square"><path id="Union" fill="%238fbffa" fill-rule="evenodd" d="M4.846 0h-3.77C0.483 0 0 0.482 0 1.077v3.77c0 0.594 0.482 1.076 1.077 1.076h3.77c0.594 0 1.076 -0.482 1.076 -1.077v-3.77C5.923 0.483 5.441 0 4.846 0Zm8.077 8.077h-3.77c-0.594 0 -1.076 0.482 -1.076 1.077v3.77c0 0.594 0.482 1.076 1.077 1.076h3.77c0.594 0 1.076 -0.482 1.076 -1.077v-3.77c0 -0.594 -0.482 -1.076 -1.077 -1.076Zm-11.846 0h3.77c0.594 0 1.076 0.482 1.076 1.077v3.77c0 0.594 -0.482 1.076 -1.077 1.076h-3.77A1.077 1.077 0 0 1 0 12.923v-3.77c0 -0.594 0.482 -1.076 1.077 -1.076Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="%232859c5" fill-rule="evenodd" d="M11.51 0.75a0.75 0.75 0 1 0 -1.5 0v1.733H8.279a0.75 0.75 0 1 0 0 1.5h1.733v1.732a0.75 0.75 0 0 0 1.5 0V3.983h1.732a0.75 0.75 0 1 0 0 -1.5h-1.732V0.75Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>');
}

.svg_social {
  display: inline-flex;
  top: 3px;
  position: relative;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Cocktail--Streamline-Core"><desc>Cocktail Streamline Icon: https://streamlinehq.com</desc><g id="cocktail--cook-alcohol-food-cocktail-drink-cooking-nutrition-alcoholic-beverage-glass"><path id="Union" fill="%238fbffa" fill-rule="evenodd" d="M2.536 0.026a1.447 1.447 0 0 0 -1.138 2.342L6.25 8.543v3.946H4a0.75 0.75 0 0 0 0 1.5h6a0.75 0.75 0 0 0 0 -1.5H7.75V8.543l4.852 -6.175A1.447 1.447 0 0 0 11.464 0.026H2.536Z" clip-rule="evenodd" stroke-width="1"></path><path id="Subtract" fill="%232859c5" fill-rule="evenodd" d="M10.197 3H3.803l2.214 2.82a1.25 1.25 0 0 0 1.966 0L10.197 3Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>');
}

.svg_leaderboard {
  display: inline-flex;
  top: 3px;
  position: relative;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Trophy--Streamline-Core" height="14" width="14"><desc>Trophy Streamline Icon: https://streamlinehq.com</desc><g id="trophy--reward-rating-trophy-social-award-media"><path id="Union" fill="%232859c5" fill-rule="evenodd" d="M0.75 0A0.75 0.75 0 0 0 0 0.75v2.77a3.75 3.75 0 0 0 3.75 3.75h0.385a0.75 0.75 0 0 0 0.75 -0.75V0.75a0.75 0.75 0 0 0 -0.75 -0.75H0.75Zm0.75 3.52V1.5h1.885v4.24A2.25 2.25 0 0 1 1.5 3.52ZM13.25 0a0.75 0.75 0 0 1 0.75 0.75v2.77a3.75 3.75 0 0 1 -3.75 3.75h-0.385a0.75 0.75 0 0 1 -0.75 -0.75V0.75a0.75 0.75 0 0 1 0.75 -0.75h3.385Zm-0.75 3.52V1.5h-1.885v4.24A2.25 2.25 0 0 0 12.5 3.52ZM7 8.654a0.75 0.75 0 0 1 0.75 0.75V12.5h2.615a0.75 0.75 0 0 1 0 1.5H7.026a0.762 0.762 0 0 1 -0.052 0h-3.34a0.75 0.75 0 0 1 0 -1.5H6.25V9.404a0.75 0.75 0 0 1 0.75 -0.75Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union_2" fill="%238fbffa" fill-rule="evenodd" d="M3.5 0.002a0.5 0.5 0 0 0 -0.5 0.5V6a4 4 0 1 0 8 0V0.502a0.5 0.5 0 0 0 -0.5 -0.5h-7Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>');
}

.svg_faqs {
  display: inline-flex;
  top: 3px;
  position: relative;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Information-Desk--Streamline-Core" height="14" width="14"><desc>Information Desk Streamline Icon: https://streamlinehq.com</desc><g id="information-desk"><path id="Subtract" fill="%238fbffa" fill-rule="evenodd" d="M6.09 2.735a4.055 4.055 0 0 1 7.844 1.442 4.054 4.054 0 0 1 -6.528 3.211A3.686 3.686 0 0 0 6.09 2.735Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union" fill="%232859c5" fill-rule="evenodd" d="M9.813 2.93c-0.275 -0.03 -0.665 -0.238 -0.665 -0.71 0 -0.512 0.46 -0.712 0.733 -0.712 0.024 0 0.048 0.002 0.07 0.004 0.275 0.03 0.666 0.237 0.666 0.709 0 0.512 -0.461 0.712 -0.734 0.712a0.633 0.633 0 0 1 -0.07 -0.004Zm0.068 0.87c0.345 0 0.625 0.28 0.625 0.626l0 1.795a0.625 0.625 0 0 1 -1.25 0l0 -1.795c0 -0.345 0.28 -0.625 0.625 -0.625Z" clip-rule="evenodd" stroke-width="1"></path><g id="Union_2"><path fill="%238fbffa" d="M4.065 8.259a2.44 2.44 0 1 0 0 -4.879 2.44 2.44 0 0 0 0 4.879Z" stroke-width="1"></path><path fill="%238fbffa" d="M0 13.137a4.065 4.065 0 1 1 8.13 0 0.407 0.407 0 0 1 -0.406 0.407H0.407A0.407 0.407 0 0 1 0 13.137Z" stroke-width="1"></path></g><path id="Vector 637 (Stroke)" fill="%232859c5" fill-rule="evenodd" d="M0.02 13.214a0.75 0.75 0 0 1 0.75 -0.75h12.462a0.75 0.75 0 0 1 0 1.5H0.77a0.75 0.75 0 0 1 -0.75 -0.75Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>');
}

.svg_feedback {
  display: inline-flex;
  top: 3px;
  position: relative;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Chat-Bubble-Oval-Smiley-2--Streamline-Core" height="14" width="14"><desc>Chat Bubble Oval Smiley 2 Streamline Icon: https://streamlinehq.com</desc><g id="chat-bubble-oval-smiley-2--messages-message-bubble-chat-oval-smiley-smile"><path id="Union" fill="%238fbffa" fill-rule="evenodd" d="M10.014 0.748a6.94 6.94 0 0 0 -8.932 9.876l-0.991 2.64a0.5 0.5 0 0 0 0.557 0.668l3.458 -0.626A6.94 6.94 0 0 0 10.014 0.748Z" clip-rule="evenodd" stroke-width="1"></path><path id="Ellipse 1067" fill="%232859c5" d="M4 4.563a0.75 0.75 0 1 0 1.5 0 0.75 0.75 0 1 0 -1.5 0" stroke-width="1"></path><path id="Ellipse 1068" fill="%232859c5" d="M8.469 4.563a0.75 0.75 0 1 0 1.5 0 0.75 0.75 0 1 0 -1.5 0" stroke-width="1"></path><path id="Ellipse 650" fill="%232859c5" d="M7 11a3.5 3.5 0 0 0 3.5 -3.5h-7A3.5 3.5 0 0 0 7 11Z" stroke-width="1"></path></g></svg>');
}

.svg_info {
  display: inline-flex;
  top: 3px;
  position: relative;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Information-Circle--Streamline-Core" height="14" width="14"><desc>Information Circle Streamline Icon: https://streamlinehq.com</desc><g id="information-circle--information-frame-info-more-help-point-circle"><path id="Ellipse 644" fill="%238fbffa" d="M0 7a7 7 0 1 0 14 0A7 7 0 1 0 0 7" stroke-width="1"></path><path id="Union" fill="%232859c5" fill-rule="evenodd" d="M8 4a1 1 0 1 1 -2 0 1 1 0 0 1 2 0Zm-3.125 6c0 -0.345 0.28 -0.625 0.625 -0.625h0.875v-2.25H6a0.625 0.625 0 1 1 0 -1.25h1c0.345 0 0.625 0.28 0.625 0.625v2.875H8.5a0.625 0.625 0 1 1 0 1.25h-3A0.625 0.625 0 0 1 4.875 10Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>');
}

.svg_logout {
  display: inline-flex;
  top: 3px;
  position: relative;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Logout-1--Streamline-Core" height="14" width="14"><desc>Logout 1 Streamline Icon: https://streamlinehq.com</desc><g id="logout-1--arrow-exit-frame-leave-logout-rectangle-right"><path id="Subtract" fill="%238fbffa" fill-rule="evenodd" d="M1.5 0A1.5 1.5 0 0 0 0 1.5v11A1.5 1.5 0 0 0 1.5 14h7a1.5 1.5 0 0 0 1.5 -1.5v-1.939a2 2 0 0 1 -0.734 -1.311H5.75a2.25 2.25 0 0 1 0 -4.5h3.516A2 2 0 0 1 10 3.439V1.5A1.5 1.5 0 0 0 8.5 0h-7Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union" fill="%232859c5" fill-rule="evenodd" d="M10.5 5a0.75 0.75 0 0 1 1.28 -0.53l2 2a0.75 0.75 0 0 1 0 1.06l-2 2A0.75 0.75 0 0 1 10.5 9V8H5.75a1 1 0 1 1 0 -2h4.75V5Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>');
}

.svg_login {
  display: inline-flex;
  top: 3px;
  position: relative;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Login-1--Streamline-Core" height="14" width="14"><desc>Login 1 Streamline Icon: https://streamlinehq.com</desc><g id="login-1--arrow-enter-frame-left-login-point-rectangle"><path id="Subtract" fill="%238fbffa" fill-rule="evenodd" d="M1.5 0A1.5 1.5 0 0 0 0 1.5v11A1.5 1.5 0 0 0 1.5 14h7a1.5 1.5 0 0 0 1.5 -1.5V9.25h-0.516a2 2 0 0 1 -3.398 1.164l-2 -2a2 2 0 0 1 0 -2.828l2 -2A2 2 0 0 1 9.484 4.75H10V1.5A1.5 1.5 0 0 0 8.5 0h-7Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union" fill="%232859c5" fill-rule="evenodd" d="M8.25 5a0.75 0.75 0 0 0 -1.28 -0.53l-2 2a0.75 0.75 0 0 0 0 1.06l2 2A0.75 0.75 0 0 0 8.25 9V8H13a1 1 0 1 0 0 -2H8.25V5Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>');
}
/* END OF SVG ICONS IN CSS */

footer {
  font-size: .8rem;
  padding: 10px;
  text-align: center;
  background-color: #f1f1f1;
}
.footer {
  display: flex;
  justifyContent: center;
  alignItems: center;
}

/* GENERAL */

.fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.btn-primary {
  background-color: #7eb5ee;
  border-color: #7eb5ee;
  margin-right: 16px;
}

.btn-link,
.btn-link:hover,
.btn-link:visited {
  color: #7eb5ee;
}

.page-container {
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 100px;
  padding-top: 1em;
  /*width: 60%; *//* Adjust the width as needed */
}


h1,
h5 {
  font-weight: bold;
}

.card {
  /*margin-left: -20px;
  margin-right: -20px;*/
}

.card-padding-override {
  padding: 0.5em;
}

.premium .currency {
  font-size: 24px;
}

.premium .amount {
  font-size: 32px;
}

.policy-number {
  font-size: 64px;
  font-weight: bold;
}

.quote-option {
  margin: 4px;
  box-sizing: border-box;
  padding: 0 16px;
  border: solid 2px white;
  border-radius: 4px;
  cursor: pointer;
}
.quote-option:hover{
  border: solid 2px #7eb5ee;
}

.quote-option-active {
  margin: 4px;
  box-sizing: border-box;
  padding: 0 16px;
  border: solid 2px white;
  border-radius: 4px;
  cursor: pointer;
}
.quote-option-active {
  border: solid 2px #7eb5ee;
}

.feed-button-text {
  /*display: block;*/
  font-size: small;
}

.members-listing div {
  padding: 5px;
  border-bottom: 1pt solid #80808040;
}

.members-listing button {
  position: absolute;
  right: 16px;
  background: none;
  border: none;
  padding: 0;
}

.members-listing-btn1 {
  position: absolute;
  right: 32px !important;
}

.post-option-active {
  margin: 8px;
  box-sizing: border-box;
  /*padding: 0 16px;*/
  border: solid 2px white;
  border-radius: 15px;
  /*cursor: pointer;*/
}
.post-option-active {
  /*border: solid 2px #7eb5ee;*/
}

.post-restricted-height {
  /*max-height: 400px;*/
  overflow: hidden;
}

.pointing-cursor {
  cursor: pointer;
}

.policy-docs {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.faq-toggle {
  position: absolute;
  right: 20px
}

.sponsored-post-text-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /*top: 15px;*/
  max-width: 200px;
}

.promoted-sponsored-info-button {
  position: absolute;
  top:0px;
  right: 5px;
  z-index: 1;
}

.post-restricted-height-rp {
  max-height: 200px;
  overflow: hidden;
  min-width: 100px;
}

.sponsored-post-image-rp {
  /*min-width: 100px;
  max-width: 100px;*/
}

.promoted-span {
  display: flex;
  position: absolute;
  top: 5px;
  right: 10px;
}

.toggle-email-setting-div-open {
  display: inherit;
}
.toggle-email-setting-div-closed {
  display: none;
}

.diagram {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
}

.top-buttons {
  padding: 0px;
}

.leader-board-lp {
  margin-right: 4px;
  box-sizing: border-box;
  /*padding: 0 16px;*/
  /*border: solid 1px #7eb5ee;*/
  border: solid 1px #fff;
  border-radius: 6px;
  /*cursor: pointer;*/
}
.leader-board-lp:hover{
  /*background-color: #7eb5ee;*/
}

.leader-board-lp-row {
  padding-bottom: 5px;
}

.leader-board-lp-card-body {
  margin: 0px !important;
  padding: 2px !important;
  /*justify-content: center;*/
  display: flex;
}

.leader-board-lp-card-body-profile {
  margin: 0px !important;
  padding: 2px !important;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.leader-board-lp-heading {
  font-weight: bold;
  cursor: pointer;
}


.leader-board-rp {
  margin-right: 4px;
  box-sizing: border-box;
  /*padding: 0 16px;*/
  /*border: solid 1px #7eb5ee;*/
  border: solid 1px #fff;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 8px;
}


.edit-option {
  margin: 4px;
  box-sizing: border-box;
  padding: 0 16px;
  border: solid 2px lightgrey;
  background-color: lightgrey;
  border-radius: 4px;
  cursor: pointer;
}
.edit-option:hover{
  border: solid 2px #7eb5ee;
}

.edit-option-active {
  margin: 4px;
  box-sizing: border-box;
  padding: 0 16px;
  border: solid 2px white;
  border-radius: 4px;
  cursor: pointer;
}
.edit-option-active {
  border: solid 2px #7eb5ee;
}

.vote-button {
  height: 35px;
  padding: 2px;
}

.post_header_logo {
  max-width: 40px;
}

.post_header_title {
  padding-left: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.vote-button:hover {
  /*border: solid 2px #7eb5ee;*/

  border-radius: 4px;
  /*height: 30px;*/
  background-color: #7eb5ee;
}

.vote-button-active {
  height: 60px;
  border: solid 2px black;
  border-radius: 4px;
  background-color: lightblue;
}

.vote-button-up {
  height: 40px;
  border: solid 2px #585858;
  border-radius: 4px;
  /*background-color: grey;*/
}

.vote-button-down {
  height: 40px;
  border: solid 2px #585858;
  border-radius: 4px;
  /*background-color: grey;*/
}

.date {
  /*color: dimgrey;*/
}

.date-today {
  /*font-style: oblique;*/
  font-weight: bold;
  /*text-decoration: underline;*/
  /*color: black;*/
}

.lodge-details-image {
  max-width: 150px;
}

.row-justified {
  justify-content: center;
}

.row-justified-and-centered {
  justify-content: center;
  text-align: center;
}

.row-justified-and-centered-flex {
  justify-content: center;
  text-align: center;
  display: flex;
}

.show_post_description {
  display: inherit;
  padding-top: 5px;
  padding-bottom: 10px;
}
.hide_post_description {
  display: none;
  padding-top: 5px;
  padding-bottom: 10px;
}

.tab {
  background-color: white;
  border: 1px solid transparent;
  color: black;
}

.tab:hover {
  text-decoration: underline;
  background-color: white;
  border: 1px solid transparent;
  color: black;
}

.tab.active {
  text-decoration: underline !important;
  background-color: white !important;
  /*border: 1px solid transparent !important;*/
  color: black !important;
}

.lodge-favorite {
  src: 'star_1.png';
}

.lodge-favorite-active {
  src: 'star_2.png';
}

.profile-badge {
  display: flex;
  justify-content: center;
}

.lodge-details-heading {
  font-size: smaller;
  text-decoration: underline;
}

.centered-header {
  display: flex;
  justify-content: center;
}

.righted-header {
  display: flex;
  justify-content: right;
}

.lefted-header {
  display: flex;
  justify-content: left;
}

.lined-heading {
  position: relative;
  display: inline-block;
}

.lined-heading::before,
.lined-heading::after {
  content: ' ';
  position: absolute;
  top: 50%;
  border-bottom: 1px solid #000; /* You can change the color and style as needed */
  width: 1000px; /* Adjust the width to span the desired length */
  max-width: 100%;
}

.lined-heading::before {
  right: 100%;
  margin-right: 10px; /* Adjust the spacing between text and line */
}

.lined-heading::after {
  left: 100%;
  margin-left: 10px; /* Adjust the spacing between text and line */
}


#wheel canvas {
  /* Your CSS styles here */
  width: 500px;
  height: 400px;
  background-color: transparent !important;
}

.noscroll {
  overflow: hidden;
}

.feed-wall-image {
  width: inherit;
  /*
  margin-top: 0;
  background-image: url('../public/landing-cover.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
  padding: 64px 0;*/
}

.feed-wall-image-rp-sponsored {
  /*width: 100px;*/
  width: 100%;
}

/* LANDING PAGE */

main.landing-page-cover {
  margin-top: 0;
  background-image: url('../public/landing-cover.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
  padding: 64px 0;
}

#section-landing-page-cover {
  color: white;
  text-align: center;
  padding: 10px 10% 64px 10%;
  box-sizing: border-box;
}
#section-landing-page-cover h3 {
  font-weight: lighter;
}

.outline {
  text-shadow: -0.6px -0.6px 0 #515151, 0.6px -0.6px 0 #515151, -0.6px 0.6px 0 #515151, 0.6px 0.6px 0 #515151;
}


/* PROGRESS BAR */
.progress-step-container {
  flex: 1;
  text-align: center;
  padding-top: 1em;
}

.progress-step-container:not(.done) .progress-step .progress-dot,
.progress-step-container:not(.done) .progress-step .progress-line {
  background-color: #dddddd;
}

.progress-step-container:not(.done) .progress-step .progress-dot {
  border: 2px solid #dddddd;
  background-color: white;
  z-index: 10;
  position: relative;
}

.progress-step-container.done .progress-step .progress-dot::before {
  content: ' ';
  display: block;
  background-image: url("../public/icon-16-tick-white.svg");
  background-size: 60%;
  background-position: 5px 5px;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.progress-step-container:last-child .progress-step .progress-line {
  background-color: transparent;
}

.progress-step-container.active .progress-step .progress-dot::before {
  content: ' ';
  display: block;
  width: 8px;
  height: 8px;
  background: #7eb5ee;
  border-radius: 50%;
  position: absolute;
  left: calc((100% - 8px) / 2);
  top: calc((100% - 8px) / 2);
}

.progress-step-container.active .progress-step .progress-dot {
  box-shadow: 0 0 10px 0 #c3c3c3;
  border: 0;
}

.progress-step {
  position: relative;
  height: 24px;
}

.progress-dot {
  position: absolute;
  left: calc(50% - 12px);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #7eb5ee;
}

.progress-line {
  position: absolute;
  left: 50%;
  right: 0;
  width: 100%;
  background-color: #7eb5ee;
  height: 2px;
  top: 11px;
  z-index: -1;
}

.progress-name {
  margin-top: 10px;
  font-size: 14px;
  color: #777777;
}

/* Full screen loader */
.full-screen-loader {
  background-color: #7eb5ee;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  text-align: center;

  /* Starting state */
  display: none;
}

.full-screen-loader.active {
  display: flex;
  position: fixed;
}

.full-screen-loader .line {
  background-color: white;
  height: 4px;
  width: 100%;
  margin-top: 28px;
}

.full-screen-loader .line .loader {
  background-color: white;
  height: 14px;
  border: 1px solid white;
  border-radius: 7px;
  top: -5px;
  position: relative;
  -webkit-transition: width .3s;
  /* Safari */
  transition: width .3s;

  width: 0;
  /* width: 50%; */
}

/* Signature */
canvas {
  background-color: #f1f1f1;
  display: block;
}

.signature-canvas {
  border: 2px solid #cccccc;
  border-radius: .25rem;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none !important;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus{
  background-color: #0753a2 !important;
  border-color: #0753a2;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0753a2;
  border-color: #0753a2;
}

#footer {
  z-index: 2;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 10px;
  background-color: white;
  justify-content: space-around;
  border-top: solid 2px #7eb5ee;
  @media (max-width: 990px) {
    display: none;
  }
}

@media (min-width: 575px) {
  .modal-dialog {
    max-width: 60% !important;
  }
}

.shrink {
  max-width: 500px !important;
}

.infoList > li {
  margin: 8px;
}

.readMoreContent > p {
  padding: 0 32px;
}

.readMoreContent > p > h2 {
  font-size: 1.3em;
  font-weight: bold;
  color: #7eb5ee
}


/*.calendar-container {
  max-width: 100%;
  overflow-x: auto;
}

.calendar-controls {
  width: 100%;
  text-align: center;
}

.calendar-table {
  width: 100%;
  table-layout: fixed;
}

.calendar-table th,
.calendar-table td {
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
  width: 14.28%; // Set each cell to 1/7th of the table width
}

.calendar-table th {
  font-weight: bold;
}

.calendar-table tbody td {
  background-color: #f5f5f5;
}

.calendar-table button {
  padding: 5px 10px;
}*/

.calendar .ul {
  display: grid;
  /*grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;*/
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  max-width: calc(80px * 7 + 10px * 6);
  flex-wrap: wrap;
  list-style: none;

  .li {
    display: flex;
    width: 5rem;
    height: 5rem;
    margin: 0.10rem;
    flex-flow: column;
    border-radius: 0.2rem;
    padding: 0.2rem;
    font-weight: 300;
    font-size: 0.8rem;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);

    time {
      /*font-size: 2rem;
      margin: 0 0 1rem 0;
      font-weight: 500;*/
    }
  }
  .blue {
    background-color: #0c5460;
    color: white;
  }

  .today {
    time {
      font-weight: 800;
    }
    background: #ffffff70;
  }
}

.matched a {
  color: green;
  font-weight: bold;
}


.fix-for-no-label-select {
  top: -20px;
  position: relative;
}





/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed; /*fixed; absolute;*/
  width: 26px;
  height: 20px;
  right: 10px;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  /*padding: 0.8em;*/
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #b8b7ad !important;
  /*padding-top: 0px !important;
  padding-bottom: 0px !important;*/
  /*font-size: small;*/
}
.bm-item-icon {
  margin-right: 5px;
  margin-left: 5px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.context-menu {
  position: absolute;
  top: 13px;
  right: 18px;
  background-color: #373a47;
  font-size: small;
  border-radius: 8px;
  width: 200px;
  padding: 10px;
  color: #b8b7ad;
}

.context-menu-closed {
  display:none;
}

.context-menu-open {
  display:block;
}